import React, { useEffect, useState } from 'react';
import '../styles/Flowchart.css';
import { useNavigate, useLocation } from 'react-router-dom';
import saikiranPDF from '../img/Prithi.pdf';

const Flowchart: React.FC = () => {
  const [assessmentClicked, setAssessmentClicked] = useState(false);
  const [feedbackClicked, setFeedbackClicked] = useState(false);
  const [paraColor, setParaColor] = useState('#2e6ca6'); // Initial color for paragraphs
  const [paraColorIndustry, setParaColorIndustry] = useState('#2e6ca6'); // Initial color for Industry Mentor paragraph
  const [reportButtonColor, setReportButtonColor] = useState('#2e6ca6'); // Initial color for "Generate Report" button
  const [startTransition, setStartTransition] = useState(false); // To track when both buttons are clicked
  const [downloadMessage, setDownloadMessage] = useState(''); // Message below Generate Report
  const navigate = useNavigate();
  
  const handleAssessmentClick = () => {
    setTimeout(() => {
      setAssessmentClicked(true); // Show message and change assessment button color to green
    }, 2000); // Delay the action by 2 seconds
  };

  const handleFeedbackClick = () => {
    setFeedbackClicked(true); // Show message and change feedback button color to green
  };

  const handleGenerateReport = () => {
    const link = document.createElement('a');
    link.href = saikiranPDF;
    link.download = 'Prithi.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setAssessmentClicked(true); // Change assessment button color to green
  };

  useEffect(() => {
    if (assessmentClicked) {
      // Both buttons clicked, start paragraph and report button transitions
      setStartTransition(true);
    }
  }, [assessmentClicked]);

  useEffect(() => {
    if (startTransition) {
      // Change Faculty Feedback paragraph color to green after 2 seconds
      const facultyTimer = setTimeout(() => {
        setParaColor('green');
      }, 2000);

      // Change Industry Mentor Feedback paragraph color to green after 4 seconds
      const industryTimer = setTimeout(() => {
        setParaColorIndustry('green');
      }, 4000);

      // Display message below the "Generate Report" button after 6 seconds
      const downloadTimer = setTimeout(() => {
        setReportButtonColor('green'); // Make the button green
        setDownloadMessage('You can now Download the report');
      }, 6000);

      // Clean up timers
      return () => {
        clearTimeout(facultyTimer);
        clearTimeout(industryTimer);
        clearTimeout(downloadTimer);
      };
    }
  }, [startTransition]);

  return (
    <div className="flowchart-container">
      <h1 className="flowchart-heading shadow-lg">
        Road Map to Creating Your Internship Report
      </h1>

      <div className="flow-item">
        <button
          className="flow-button"
          style={{ backgroundColor: assessmentClicked ? 'green' : '#2e6ca6', boxShadow: '0 4px 8px rgb(0,0,0)' }}
          onClick={handleAssessmentClick}
        >
          Take Assessment
        </button>
        {assessmentClicked && (
          <span className="flow-message" style={{ color: 'green', marginLeft: '10px' }}>
            You have scored 8 out of 10
          </span>
        )}
        <svg className="mt-2 mb-2 w-[65px] h-[55px]" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 16 16" viewBox="0 0 16 16" id="arrows">
          <g display="none" fill="#060000" className="color000000 svgShape">
            <polygon fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points=".5 5.5 .5 8 8 15.5 15.5 8 15.5 5.5 8 13" className="colorStroke000000 svgStroke"></polygon>
            <polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="9.3 6.7 15.5 .5 15.5 3 8 10.5 .5 3 .5 .5 8 8"></polyline>
          </g>
          <g display="none" fill="#060000" className="color000000 svgShape">
            <path d="M15.7,5c-0.2-0.1-0.4,0-0.5,0.1L8,12.3L0.9,5.1C0.7,5,0.5,5,0.3,5C0.1,5.1,0,5.3,0,5.5V8c0,0.1,0.1,0.3,0.1,0.4l7.5,7.5C7.7,16,7.9,16,8,16s0.3,0,0.4-0.1l7.5-7.5C15.9,8.3,16,8.1,16,8V5.5C16,5.3,15.9,5.1,15.7,5z M15,7.8l-7,7l-7-7V6.7l6.6,6.6c0.2,0.2,0.5,0.2,0.7,0L15,6.7V7.8z" fill="#060000" className="color000000 svgShape"></path>
            <path d="M15.7,0c-0.2-0.1-0.4,0-0.5,0.1L8.9,6.4c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0L15,1.7v1.1l-7,7l-7-7V1.7l6.6,6.6c0.2,0.2,0.5,0.2,0.7,0s0.2-0.5,0-0.7L0.9,0.1C0.7,0,0.5,0,0.3,0C0.1,0.1,0,0.3,0,0.5V3c0,0.1,0.1,0.3,0.1,0.4l7.5,7.5C7.7,11,7.9,11,8,11s0.3,0,0.4-0.1l7.5-7.5C15.9,3.3,16,3.1,16,3V0.5C16,0.3,15.9,0.1,15.7,0z" fill="#060000" className="color000000 svgShape"></path>
          </g>
          <g fill="#060000" className="color000000 svgShape">
            <path fill="#000808" d="M15.7,5c-0.2-0.1-0.4,0-0.5,0.1L8,12.3V16c0.1,0,0.3,0,0.3-0.2l7.5-7.5C16,8.3,16,8.1,16,8V5.5C16,5.3,15.9,5.1,15.7,5z" className="color27a7c4 svgShape"></path>
            <path fill="#00000a" d="M15.7,0c-0.2-0.1-0.4,0-0.5,0.1L8,7.3V11c0.1,0,0.3,0,0.3-0.2l7.5-7.5C16,3.3,16,3.1,16,3V0.5C16,0.3,15.9,0.1,15.7,0z" className="color3bb3e3 svgShape"></path>
          </g>
        </svg>
      </div>

      <div className="flow-item">
        <p className="flow-paragraph" style={{ backgroundColor: paraColor, boxShadow: '0 4px 8px rgb(0,0,0)' }}>
          Awaiting Feedback: Industry
        </p>
        <svg className="mt-2 mb-2 w-[65px] h-[55px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M15.7,5c-0.2-0.1-0.4,0-0.5,0.1L8,12.3V16c0.1,0,0.3,0,0.3-0.2l7.5-7.5C16,8.3,16,8.1,16,8V5.5C16,5.3,15.9,5.1,15.7,5z M15,7.8l-7,7l-7-7V6.7l6.6,6.6c0.2,0.2,0.5,0.2,0.7,0L15,6.7V7.8z" fill="#060000"></path>
          <path d="M15.7,0c-0.2-0.1-0.4,0-0.5,0.1L8.9,6.4c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0L15,1.7v1.1l-7,7l-7-7V1.7l6.6,6.6c0.2,0.2,0.5,0.2,0.7,0s0.2-0.5,0-0.7L0.9,0.1C0.7,0,0.5,0,0.3,0C0.1,0.1,0,0.3,0,0.5V3c0,0.1,0.1,0.3,0.1,0.4l7.5,7.5C7.7,11,7.9,11,8,11s0.3,0,0.4-0.1l7.5-7.5C15.9,3.3,16,3.1,16,3V0.5C16,0.3,15.9,0.1,15.7,0z" fill="#060000"></path>
        </svg>
      </div>

      <div className="flow-item">
        <button
          className="flow-button"
          style={{ backgroundColor: reportButtonColor, boxShadow: '0 4px 8px rgb(0,0,0)' }}
          onClick={handleGenerateReport}
        >
          Generate Report
        </button>
        {downloadMessage && (
          <span className="flow-message" style={{ color: 'green', marginLeft: '10px' }}>
            {downloadMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default Flowchart;
