import React, { useState } from 'react';
import Chatbot from '../components/Chatbot';
import { useNavigate } from 'react-router-dom';

import '../styles/A.css'; // Ensure this CSS file includes necessary styles

const Reg: React.FC = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
const navigate = useNavigate();
  const handleRegisterClick = () => {
    navigate('/acc')
  };
  
  const handleShowLogin = () => {
    navigate('/login')
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="backs bg-custom-teal"></div>
      <div className="fixed top-0 left-0 w-full h-14 bg-custom-tea z-10 flex items-center">
        <img src="https://iimstc.com/wp-content/uploads/2021/10/log.png" alt="Logo" className="h-12" />
      </div>
      <div className="backgrounds bg-custom-teal"></div>
      
      <div className="flex flex-col items-center justify-center h-full">
        
          <div className="w-1/3 text-white md:pl-4 md:pr-4 pr-8 md:ml-auto font-jacques">
            <h1 className="font-bold text-3xl mb-4 text-center">Welcome to Novaversity</h1>

            <p className="text-lg mb-6 text-justify">
              An INTERNSHIP MANAGEMENT SYSTEM (IMS) streamlines the entire internship life cycle, from onboarding to supervision and evaluation, ensuring a structured and efficient process. It provides tools for managing intern applications, assigning projects, tracking progress, and gathering feedback, enhancing the overall experience for both interns and the organization. By centralizing these functions, an IMS improves communication, accountability, and the ability to track the progress of the internship to the management.
            </p>

            <div className="flex flex-col items-center">
              <button 
                onClick={handleRegisterClick} 
                className="bg-teal-400 text-white px-6 py-3 rounded-lg mb-4 hover:bg-teal-500"
              >
                Register Now
              </button>
              <a 
                onClick={handleShowLogin} 
                className="text-blue-200 underline hover:text-blue-100 cursor-pointer"
              >
                Already Registered? Click here to Login
              </a>
            </div>
          </div>
        ) : (
          
      
      </div>

      <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
        {isChatbotOpen ? "Close Chatbot" : "Help?"}
      </button>

      {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
    </div>
  );
};

export default Reg;
