import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface DailyLog {
  id?: number;
  day: string;
  date: string;
  arrivalTime: string;
  departureTime: string;
  remarks: string;
  department: string;
  finishedProduct: string;
  hodName: string;
  hodEmail: string;
  mainPoints: string;
  userId: string;
}

interface DailyLogsProps {
  aicteInternId: number;
  onLogSubmit: (log: DailyLog) => Promise<void>;
  initialLog: DailyLog | null;
  mode: 'create' | 'edit' | 'view';
  readOnly?: boolean; // Add this line
}

const DailyLogForm: React.FC<DailyLogsProps> = ({ aicteInternId, onLogSubmit, initialLog, mode, readOnly = false }) => {
  const [log, setLog] = useState<DailyLog>({
    id: initialLog?.id,
    day: initialLog?.day || '',
    date: initialLog?.date || '',
    arrivalTime: initialLog?.arrivalTime || '',
    departureTime: initialLog?.departureTime || '',
    remarks: initialLog?.remarks || '',
    department: initialLog?.department || '',
    finishedProduct: initialLog?.finishedProduct || '',
    hodName: initialLog?.hodName || '',
    hodEmail: initialLog?.hodEmail || '',
    mainPoints: initialLog?.mainPoints || '',
    userId: '',
  });

  const [isMaximized, setIsMaximized] = useState(false);
  const [aicteIntern, setAicteIntern] = useState<{ hodName: string; hodEmail: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setLog((prevLog) => ({
            ...prevLog,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID:', error);
        setError('Failed to fetch user ID. Please try again.');
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
  const fetchFacultyInfo = async () => {
    try {
      const { userId } = log;
      if (userId) {
        // Fetch faculty information by userId
        const response = await axios.get(`/api/aicte-interns/faculty/${userId}`);
        setLog((prevLog) => ({
          ...prevLog,
          hodName: response.data.hodName,
          hodEmail: response.data.hodEmail,
        }));
      }
    } catch (error) {
      const typedError = error as any; // Type assertion
      if (typedError.response && typedError.response.status === 404) {
        setError('Faculty information not found.');
      } else {
        setError('An error occurred while fetching faculty information.');
      }
      console.error('Error fetching faculty information:', typedError);
    }
  };

  fetchFacultyInfo();
}, [log.userId]);


  useEffect(() => {
    const fetchDailyLog = async () => {
      try {
        if (aicteInternId && log.date && log.userId) {
          const response = await axios.get(`/api/daily-logs/${aicteInternId}/${log.date}`);
          if (response.data && response.data.dailyLog) {
            setLog(response.data.dailyLog);
          }
        }
      } catch (error) {
        console.error('Error fetching existing daily log:', error);
       
      } finally {
        setLoading(false);
      }
    };

    fetchDailyLog();
  }, [aicteInternId, log.date, log.userId]);

  useEffect(() => {
    if (aicteIntern) {
      setLog((prevLog) => ({
        ...prevLog,
        hodName: aicteIntern.hodName,
        hodEmail: aicteIntern.hodEmail,
      }));
    }
  }, [aicteIntern]);

  useEffect(() => {
    if (initialLog) {
      setLog((prevLog) => ({
        ...prevLog,
        ...initialLog,
      }));
    }
  }, [initialLog]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLog((prevLog) => ({
      ...prevLog,
      [name]: value,
    }));
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    alert('Pasting is not allowed in this field.');
  };

  const validateForm = () => {
    if (
      !log.day ||
      !log.date ||
      !log.arrivalTime ||
      !log.departureTime ||
      !log.remarks ||
      !log.department ||
      !log.finishedProduct ||
      !log.mainPoints
    ) {
      setError('Please fill out all required fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError(null);

    try {
      const logToSave = {
        ...log,
        id: log.id?.toString(),
      };

      if (log.id) {
        await axios.put(`/api/daily-logs/${log.userId}/${log.date}`, logToSave);
        alert('Daily log updated successfully');
      } else {
        await axios.post('/api/daily-logs/user', logToSave);
        alert('Daily log created successfully');
        setLog({
          id: undefined,
          day: '',
          date: '',
          arrivalTime: '',
          departureTime: '',
          remarks: '',
          department: '',
          finishedProduct: '',
          hodName: '',
          hodEmail: '',
          mainPoints: '',
          userId: '',
        });
      }
      onLogSubmit(log);
    } catch (error) {
      console.error('Error submitting daily log:', error);
      setError('Failed to submit the log. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
      </div>
    );
  }

  return (
    <div className={`flex justify-center rounded-lg shadow-lg items-center min-h-screen bg-gray-100 ${isMaximized ? 'w-full h-full' : 'w-[890px] h-[800px]'}`} style={{ transform: 'translateX(-16px)' }}>
      <form
        onSubmit={handleSubmit}
        className={`p-6 bg-white shadow-md rounded-md transition-all duration-300 ${isMaximized ? 'w-full h-full' : 'w-[900px] h-[800px]'}`}
      >
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-medium">Daily Log Form</h2>
          {mode !== 'view' && (
            <button
              type="button"
              onClick={toggleMaximize}
              className="text-sm text-blue-600 hover:underline"
            >
              {isMaximized ? 'Minimize' : 'Maximize'}
            </button>
          )}
        </div>

        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

        <table className="w-full border border-gray-300 border-collapse">
          <tbody>
            <tr className="border-b border-gray-300">
              <td className="px-4 py-2">
                <label htmlFor="day" className="block text-sm font-medium text-gray-700">Day</label>
                <input
                  type="text"
                  id="day"
                  name="day"
                  value={log.day}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
              <td className="px-4 py-2">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={log.date}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
            </tr>
            <tr className="border-b border-gray-300">
              <td className="px-4 py-2">
                <label htmlFor="arrivalTime" className="block text-sm font-medium text-gray-700">Arrival Time</label>
                <input
                  type="time"
                  id="arrivalTime"
                  name="arrivalTime"
                  value={log.arrivalTime}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
              <td className="px-4 py-2">
                <label htmlFor="departureTime" className="block text-sm font-medium text-gray-700">Departure Time</label>
                <input
                  type="time"
                  id="departureTime"
                  name="departureTime"
                  value={log.departureTime}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
            </tr>
            <tr className="border-b border-gray-300">
              <td className="px-4 py-2">
                <label htmlFor="remarks" className="block text-sm font-medium text-gray-700">Remarks</label>
                <textarea
                  id="remarks"
                  name="remarks"
                  value={log.remarks}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable textarea
                ></textarea>
              </td>
              <td className="px-4 py-2">
                <label htmlFor="department" className="block text-sm font-medium text-gray-700">Department</label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={log.department}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
            </tr>
            <tr className="border-b border-gray-300">
              <td className="px-4 py-2">
                <label htmlFor="finishedProduct" className="block text-sm font-medium text-gray-700">Finished Product</label>
                <input
                  type="text"
                  id="finishedProduct"
                  name="finishedProduct"
                  value={log.finishedProduct}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable input
                />
              </td>
               <td className="px-4 py-2">
              <label htmlFor="hodName" className="block text-sm font-medium text-gray-700">HOD Name</label>
              <input
                type="text"
                id="hodName"
                name="hodName"
                value={log.hodName}
                onChange={handleChange}
                onPaste={handlePaste}
                disabled={readOnly}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />
            </td>
          </tr>
          <tr className="border-b border-gray-300">
            <td className="px-4 py-2">
              <label htmlFor="hodEmail" className="block text-sm font-medium text-gray-700">HOD Email</label>
              <input
                type="email"
                id="hodEmail"
                name="hodEmail"
                value={log.hodEmail}
                onChange={handleChange}
                onPaste={handlePaste}
                disabled={readOnly}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />
            </td>
              <td className="px-4 py-2">
                <label htmlFor="mainPoints" className="block text-sm font-medium text-gray-700">Main Points</label>
                <textarea
                  id="mainPoints"
                  name="mainPoints"
                  value={log.mainPoints}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  className="mt-1 block w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  disabled={readOnly} // Use readOnly prop to disable textarea
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        {mode !== 'view' && !readOnly && ( // Also check for readOnly before showing submit button
          <div className="mt-4">
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white font-medium py-2 px-4 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500"
            >
              {mode === 'create' ? 'Submit' : 'Update'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default DailyLogForm;
