import React, { useState } from 'react';

interface FeedbackFormProps {
    onClose: () => void;
}

const feedbackCategories = [
    "Behaviors",
    "Performs in a dependable manner",
    "Cooperates with co-workers and supervisors",
    "Shows interest in work",
    "Learns quickly",
    "Shows initiative",
    "Produces high quality work",
    "Accepts responsibility",
    "Accepts criticism",
    "Demonstrates organizational skills",
    "Uses technical knowledge and expertise",
    "Shows good judgment",
    "Demonstrates creativity/originality",
    "Analyzes problems effectively",
    "Is self-reliant",
    "Communicates well",
    "Writes effectively",
    "Has a professional attitude",
    "Gives a professional appearance",
    "Is punctual",
    "Uses time effectively",
    "Overall Experience"
];


const FeedbackFormIndustry: React.FC<FeedbackFormProps> = ({ onClose }) => {
    const [feedback, setFeedback] = useState<string[]>(new Array(feedbackCategories.length).fill(''));

    const handleFeedbackChange = (index: number, value: string) => {
        const updatedFeedback = [...feedback];
        updatedFeedback[index] = value;
        setFeedback(updatedFeedback);
    };

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent default form submission behavior
        console.log('Feedback submitted:', feedback);
        onClose(); // Close the form after submission
    };

    const handleCancel = () => {
        setFeedback(new Array(feedbackCategories.length).fill('')); // Reset feedback
        onClose(); // Close the form without submitting
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-center text-lg font-semibold mb-4">Give Your Feedback</h2>
                <div className="max-h-[70vh] overflow-y-auto scrollbar-hide pr-6">
                    {feedbackCategories.map((category, index) => (
                        <div key={index}>
                            <div className="flex justify-between">
                                <h3 className="font-semibold">{index + 1}. {category}</h3>
                            </div>
                            <div className="flex justify-between mb-5">
                                {["Excellent", "Very Good", "Satisfactory", "Not Satisfactory"].map(option => (
                                    <label key={option} className="flex items-center">
                                        <input
                                            type="radio"
                                            value={option}
                                            checked={feedback[index] === option}
                                            onChange={() => handleFeedbackChange(index, option)}
                                            className="mr-2 ml-2"
                                        />
                                        {option}
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center">
                        <button
                            onClick={handleSubmit}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-4"
                        >
                            Submit
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackFormIndustry;

