import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Chatbot from '../components/Chatbot';
import { fetchUserProfile, updateUserProfile, UserProfile } from '../services/userService';
import '../styles/student.css';
import { CiLogout } from 'react-icons/ci';
import { AiOutlineUser, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'; // Import AiOutlineEdit
import { MdAccountBox, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { FaCalendar, FaTools, FaBars } from 'react-icons/fa';
import { IoSchoolOutline } from "react-icons/io5";
import { BsXbox } from "react-icons/bs";

import InternshipProgram from '../components/InternshipProgram';
import NormInternship from '../components/NormInternship';
import AccountDetails from '../components/AccountDetails';

import PaymentComponent from '../components/PaymentComponent';
import FullCalendarComponent from '../components/FullCalendarComponent';
import Flowchart from '../components/Flowchart';
import PreScreening from '../components/PreScreening';
import settingsImage from '../img/settings_10024262.jpg';
import userImage from '../img/account_12099245 (1).png';
import logoutImage from '../img/log-out_10117545.png';
import internshipImage from '../img/internship_8920823.png';
import internshipIcon from '../img/internship_16648163.png';
import { IoMdGlobe } from "react-icons/io";
import { MdOutlineSchool } from "react-icons/md";
import annualReportIcon from '../img/annual-report_17340453.png';
import pen from '../img/pen_10977638.png';
import info from '../img/information-point_11606639 (1).png';
import saikiranPDF from '../img/saikiran.pdf';

const TestAttendingMode = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false); // Track editing state
  const [editProfile, setEditProfile] = useState<UserProfile | null>(null);
  const [activeSection, setActiveSection] = useState<'account' |'report'| 'internshipProgram' | 'normInternship' | 'aicteIntern' | 'payment' | 'calendar' | 'preScreening'>('account');
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [showAssessmentButtons, setShowAssessmentButtons] = useState(false);

  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
const [showMessage, setShowMessage] = useState<boolean>(false);
  const [aicteFormSubmitted, setAicteFormSubmitted] = useState(false);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };
  const handleGenerateReport = () => {
    const link = document.createElement('a');
    link.href = saikiranPDF;
    link.download = 'Saikiran.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

 const handleEnrollClick = () => {
    setShowMessage(true);
  };

  const handleTakeAssessment = () => {
    navigate('/test');
  };

  const handleGiveFeedback = () => {
    setFeedbackGiven(true);
    navigate('/stu');
  }
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const user = await fetchUserProfile();
        if (user) {
          setUserProfile(user);
        } else {
          setError('Error fetching user profile. Please try again.');
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching user profile. Please try again.');
        setLoading(false);
        console.error('Error fetching user profile:', error);
      }
    };

    fetchProfile();
  }, []);


  useEffect(() => {
    if (location.pathname.includes('normInternship')) {
      setActiveSection('normInternship');
    }
  }, [location.pathname]);

  const handleEdit = () => {
    setIsEditing(true);
    setEditProfile(userProfile);
  };

  const handleSave = async () => {
    if (editProfile) {
      try {
        const updatedProfile = await updateUserProfile(editProfile);
        setUserProfile(updatedProfile.user);
        setIsEditing(false);
      } catch (error) {
        setError('Error updating profile. Please try again.');
        console.error('Error updating profile:', error);
      }
    }
  };
  const handleProfileUpdate = (updatedProfile: UserProfile) => {
    setUserProfile(updatedProfile);
    setEditProfile(updatedProfile);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editProfile && 'target' in e) {
      const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
      setEditProfile({
        ...editProfile,
        [name]: value
      });
    }
  };

  const handleSidebarClick = (section: 'account' | 'internshipProgram' | 'normInternship' | 'aicteIntern' | 'payment' | 'calendar' | 'preScreening' | 'report') => {
    if (section === 'internshipProgram') {
      if (userProfile?.InternshipApproved && userProfile?.paymentCompleted) {
        setActiveSection('aicteIntern');
      } else if (userProfile?.InternshipApproved && !userProfile?.paymentCompleted) {
        setActiveSection('payment');
      } else {
        setActiveSection('preScreening');
      }
    } else {
      setActiveSection(section);
    }
  };

  const handleAicteFormSubmit = () => {
    setUserProfile((prevUserProfile) => {
      if (prevUserProfile) {
        return { ...prevUserProfile, aicteFormSubmitted: true };
      } else {
        return null;
      }
    });
    setActiveSection('calendar');
  };


  const handlePaymentComplete = (): Promise<void> => {
    setUserProfile((prevUserProfile) => {
      if (prevUserProfile) {
        return { ...prevUserProfile, paymentCompleted: true };
      } else {
        return null;
      }
    });
    return Promise.resolve();
  };

  const isFile = (photo: any): photo is File => photo instanceof File;

  const isBuffer = (photo: any): photo is { type: 'Buffer'; data: number[] } =>
    photo && photo.type === 'Buffer' && Array.isArray(photo.data);

  const renderPassportPhoto = (passportPhoto: File | { type: 'Buffer'; data: number[] } | null | undefined) => {
    if (!passportPhoto) {
      return 'No photo available';
    }

    if (isFile(passportPhoto)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        if (dataUrl) {
          return <img src={dataUrl} alt="Passport Photo Preview" className="imgPreview" />;
        }
      };
      reader.readAsDataURL(passportPhoto);
      return null; // The image will be displayed once FileReader finishes loading
    } else if (isBuffer(passportPhoto)) {
      const base64String = btoa(String.fromCharCode(...passportPhoto.data));
      const dataUrl = `data:image/jpeg;base64,${base64String}`;
      return <img src={dataUrl} alt="Passport Photo Preview" className="imgPreview" />;
    } else {
      return 'No photo available';
    }

  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [isProgramsDropdownOpen, setIsProgramsDropdownOpen] = useState(false);
  const [isInternshipDropdownOpen, setIsInternshipDropdownOpen] = useState(false);

  const toggleProgramsDropdown = () => {
    setIsProgramsDropdownOpen(!isProgramsDropdownOpen);
  };

  const toggleInternshipDropdown = () => {
    setIsInternshipDropdownOpen(!isInternshipDropdownOpen);
  };


  const bodyStyle = {
    backgroundColor: '#f0f0f0', // Change this to your desired color
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'; // Set background color to white

    // Clean up
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (

    <div className={`relative flex ${isSidebarMinimized ? 'ml-16' : 'ml-64'} transition-all duration-300`}>


      <div className="flex h-full">
        {/* Content Sidebar */}
        <div
          className={`bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-full z-10 left-0 content-sidebar ${isCollapsed ? 'w-24' : ''}`}
          style={{
            width: isCollapsed ? '6rem' : '16rem', // Use '16rem' when not collapsed
            background: 'linear-gradient(45deg, #071523, #001932)',
            borderRadius: '20px' // Add your gradient here
          }}
        >
          <div className="flex flex-col items-center">
            <img src="https://iimstc.com/wp-content/uploads/2021/10/log.png" alt="Logo" className="h-12 mb-6" />
            {userProfile?.passportPhoto && 'data' in userProfile.passportPhoto && (
              <img
                src={`data:image/jpeg;base64,${btoa(
                  String.fromCharCode(...new Uint8Array(userProfile.passportPhoto.data))
                )}`}
                alt="Passport"
                className="h-24 w-24 object-cover rounded-full"
              />
            )}
            <h2 className="text-xl font-bold text-white mt-4 text-center">Hi, {userProfile?.name}</h2>
          </div>
          {loading && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
              <div className="p-6 m-4 rounded-md shadow-lg">
                <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12H4zm2 5.291A7.962 7.962 0 014 19.708a7.962 7.962 0 0114-5.291zm12 0a7.962 7.962 0 013 5.291 7.962 7.962 0 01-14 5.291z"></path>
                </svg>
                <h2 className="text-xl font-semibold text-white">Loading...</h2>
              </div>
            </div>
          )}
<div className="flex flex-col mt-4">
  <button
    onClick={() => handleSidebarClick('account')}
    style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '15px',
                padding: '8px',
                borderRadius: '8px',
                borderBottom: activeSection === 'account' ? '2px solid #000' : 'none', // Underline for selected button
                transition: 'background-color 0.3s ease',
              }}
            >
    <AiOutlineUser size={30} style={{ marginRight: '16px' }} />
    {!isCollapsed && <span className="text-left">Account</span>}
  </button>

  {/* Internship section, including dropdown */}
  <div className="relative">
    <button
      onClick={toggleProgramsDropdown}
       style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  padding: '8px',
                  borderRadius: '8px',
                  borderBottom: activeSection === 'internshipProgram' ? '2px solid #000' : 'none', // Underline for selected button
                  transition: 'background-color 0.3s ease',
                }}
              >
      <IoMdGlobe size={30} />
      {!isCollapsed && <span className="ml-4">Internship</span>}
    </button>
    
    {isProgramsDropdownOpen && (
      <div className="ml-10 mt-2 text-white rounded-md max-h-32 overflow-y-auto">
        <button className="block px-4 py-2 text-sm font-bold w-full hover:bg-gray-600 text-left"
          onClick={() => handleSidebarClick('internshipProgram')}>
          International Internship
        </button>
        <button className="block px-4 py-2 text-sm font-bold w-full hover:bg-gray-600 text-left"
          onClick={() => handleSidebarClick('normInternship')}>
          National Internship
        </button>
      </div>
    )}
  </div>

  {/* Conditional Rendering for Daily Logs and Complete Internship */}
  {userProfile?.aicteFormSubmitted && (
    <>
      <button
        onClick={() => handleSidebarClick('calendar')}
         style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '15px',
                padding: '8px',
                borderRadius: '8px',
                borderBottom: activeSection === 'calendar' ? '2px solid #000' : 'none', // Underline for selected button
                transition: 'background-color 0.3s ease',
              }}
            >
        <FaCalendar size={25} style={{ marginRight: '16px' }} />
        {!isCollapsed && <span className="text-left">Daily Logs</span>}
      </button>

      <button
        onClick={() => handleSidebarClick('report')}
        className={`flex items-center p-2 mb-4 ${activeSection === 'internshipProgram' ? 'bg-teal-700' : ''}`}
              >
        <IoMdGlobe size={30} style={{ marginRight: '16px' }} />
        {!isCollapsed && <span className="text-left">Complete Internship</span>}
      </button>
    </>
  )}
</div>

          <div className="flex flex-col justify-end flex-grow">
            <div className="mt-auto">
              <button
                onClick={handleLogout}
                className="flex items-center text-white bg-red-500 hover:bg-red-600 p-2 rounded w-[100%]"
                style={{
                  padding: '8px',
                  borderRadius: '8px',
                  transition: 'background-color 0.3s ease',          
                    alignItems: 'center',
                    marginBottom: '8px',
                }}
              >
                <CiLogout size={30} style={{ marginRight: '16px' }} />
                {!isCollapsed && <span className="text-left">Logout</span>}
              </button>
            </div>
          </div>

        </div>
      </div>






      <div className="flex-1  ml-56   p-6 w-[90%]">
        <div>
          {activeSection === 'account' && userProfile && (
            <AccountDetails userProfile={userProfile}
              isEditing={isEditing}
              editProfile={editProfile ?? null}
              handleChange={handleChange}
              handleSave={handleSave}
              handleEdit={handleEdit}
              onProfileUpdate={handleProfileUpdate}


            />
          )}
          {activeSection === 'internshipProgram' && (
            <div>
              <div className="flex justify-between mb-4">

                {(!userProfile?.InternshipApproved || !userProfile?.paymentCompleted) && (
                  <button
                    onClick={() => handleSidebarClick('preScreening')}
                    className="bg-teal-600 text-white font-semibold py-2 px-4 rounded hover:bg-teal-500 transition"
                    aria-label="Complete Pre-Screening"
                  >
                    Complete Pre-Screening
                  </button>
                )}
              </div>

              <PreScreening />
              ) : (

            </div>
          )}
          {activeSection === 'normInternship' && (
            <>
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold" style={{ color: "Black" }} >Industry/Research Internship Program</h1>

              </div>
              <NormInternship />
              <div className="enroll-container">
              
                </div>
            </>
          )}

          {activeSection === 'payment' && !userProfile?.paymentCompleted && userProfile && (
            <PaymentComponent userProfile={userProfile} onPaymentComplete={handlePaymentComplete} />
          )}
          {activeSection === 'calendar' && <FullCalendarComponent />}
          {activeSection === 'report' && <Flowchart />}
          {activeSection === 'preScreening' && <PreScreening />}
        </div>
      </div>

      {isHelpOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Help</h2>
              <button
                onClick={() => setIsHelpOpen(false)}
                className="text-gray-500"
                aria-label="Close Help"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <p>If you have any questions or need assistance, please contact support.</p>

          </div>
        </div>
      )}
      <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
        {isChatbotOpen ? "Close Chatbot" : "Help?"}
      </button>

      {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
    </div>


  );
};

export default TestAttendingMode;
