import React, { useState } from 'react';
import '../styles/accessFormCss.css';

const AccessForm: React.FC = () => {
  const [accessType, setAccessType] = useState<string>(''); 

  const handleGoBack = () => {
    window.location.href = '../index.html';
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    alert('Form submitted!');
  };

 return (
    <div className="access-form-container">
      <form id="accessForm" className="access-form" onSubmit={handleSubmit}>
        <fieldset className="form-section">
          <legend className="form-legend " style={{ fontSize: '24px', textAlign: 'center', display: 'block', width: '100%' }}>
            <span className="form-text" style={{ fontSize: '24px', textAlign: 'center', display: 'block', width: '100%', margin: '0 auto' }}>
              <strong>Access Request Form</strong>
            </span>
          </legend>

          <div className="form-row">
            {/* Email */}
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email</label>
              <input id="email" name="email" type="email" placeholder="Enter Email" className="form-input" required />
            </div>

            {/* Types of Access */}
            <div className="form-group">
              <label htmlFor="accessType" className="form-label">Types of Access</label>
              <select
                id="accessType"
                name="accessType"
                className="form-input"
                required
                value={accessType}
                onChange={(e) => setAccessType(e.target.value)} // Update state on change
              >
                <option value="">Select Access Type</option>
                <option value="industry_facilitator">Industry Facilitator</option>
                <option value="faculty">Faculty</option>
                <option value="lic">Local Inspection Committee</option>
                <option value="management">Management</option>
                <option value="principal">Principal</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            {/* Name */}
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name</label>
              <input id="name" name="name" type="text" placeholder="Enter Name" className="form-input" required />
            </div>

            {/* Email ID */}
            <div className="form-group">
              <label htmlFor="emailID" className="form-label">Phone number</label>
              <input id="emailID" name="emailID" type="email" placeholder="Enter Email ID" className="form-input" required />
            </div>
          </div>

          {/* New Address Field */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="address" className="form-label">Address</label>
              <input id="address" name="address" type="text" placeholder="Enter Address" className="form-input" required />
            </div>
            <div className="form-group">
              <label htmlFor="idCard" className="form-label">Upload Photo</label>
              <input id="idUploadPhoto" name="idUploadPhoto" type="file" className="form-input" accept="image/*" required />
              <small>Supported file format. Max 10 MB.</small>
            </div>
          </div>
          

          <div className="form-row">
            {/* Department */}
            <div className="form-group">
              <label htmlFor="department" className="form-label">Department</label>
              <input id="department" name="department" type="text" placeholder="Enter Department" className="form-input" required />
            </div>

            {/* Company / College Name */}
            <div className="form-group">
              <label htmlFor="company" className="form-label">Company / College Name</label>
              <input id="company" name="company" type="text" placeholder="Enter Company or College Name" className="form-input" required />
            </div>
          </div>

          {/* Conditionally render additional fields for Industry Facilitator BELOW the Company/College Name */}
          {accessType === 'industry_facilitator' && (
            <div className="form-row">
              {/* UAN No */}
              <div className="form-group">
                <label htmlFor="uanNo" className="form-label">UAN No</label>
                <input
                  id="uanNo"
                  name="uanNo"
                  type="text"
                  placeholder="Enter UAN No"
                  className="form-input"
                  required
                />
              </div>

              {/* GST/VAT No */}
              <div className="form-group">
                <label htmlFor="gstVatNo" className="form-label">GST/VAT No</label>
                <input
                  id="gstVatNo"
                  name="gstVatNo"
                  type="text"
                  placeholder="Enter GST/VAT No"
                  className="form-input"
                  required
                />
              </div>
            </div>
          )}

          <div className="form-row">
            {/* Designation */}
            <div className="form-group">
              <label htmlFor="designation" className="form-label">Designation</label>
              <input id="designation" name="designation" type="text" placeholder="Enter Designation" className="form-input" required />
            </div>

            {/* Manager Name / HOD Name */}
            <div className="form-group">
              <label htmlFor="managerName" className="form-label">Manager / HOD Name</label>
              <input id="managerName" name="managerName" type="text" placeholder="Enter Manager or HOD Name" className="form-input" required />
            </div>
          </div>

          <div className="form-row">
            {/* Manager / HOD Email ID */}
            <div className="form-group">
              <label htmlFor="managerEmail" className="form-label">Manager / HOD Email ID</label>
              <input id="managerEmail" name="managerEmail" type="email" placeholder="Enter Manager or HOD Email ID" className="form-input" required />
            </div>

            {/* College / Organization ID Card Upload */}
            <div className="form-group">
              <label htmlFor="idCard" className="form-label">College / Organization ID Card Upload</label>
              <input id="idCard" name="idCard" type="file" className="form-input" accept="image/*" required />
              <small>Supported file format. Max 10 MB.</small>
            </div>
          </div>
        </fieldset>

        <div className="access-form-btn-container">
          <button type="submit" className="form-btn access-form-btn-submit">Submit</button>
          <button type="button" className="form-btn access-form-btn-go-back" onClick={handleGoBack}>Go Back</button>
        </div>
      </form>
    </div>
  );
};

export default AccessForm;
