import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaEye, FaPen } from 'react-icons/fa';
import '../styles/Account.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserProfile } from '../services/userService';
import { createInitialInternshipDetails, updateFacultyInfo, updateIndustryFacilitatorInfo, getInitialInternshipDetails, getFacultyInfo, getIndustryFacilitatorInfo } from '../services/aicteInternService';

interface AccountDetailsProps {
  userProfile: UserProfile;
  isEditing: boolean;
  editProfile: UserProfile | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSave: () => Promise<void>;
  handleEdit: () => void;
  onProfileUpdate: (updatedProfile: UserProfile) => void;
}

const formatDate = (dateString: string) => {
  if (!dateString) return '';
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

type TableId = 'student-detail' | 'education-info' | 'faculty-info' | 'industry-facilitator' | 'internship-details';
type FormId = 'personal-details-form' | 'education-info-form' | 'faculty-info-form' | 'industry-facilitator-form' | 'internship-details-form';

interface AccountFormState {
  userId: number;
  facultyInfo: {
    hodName: string;
    hodEmail: string;
    facultyName: string;
    facultyEmail: string;
    facultyPhone: string;
    campusAddress: string;
    campusPhoneNumber: string;
    campusFaxNumber: string;
    schoolCityState: string;
    academicYear: string;
    schoolEmail: string;
  };
  industryInfo: {
    internshipCompany: string;
    GST_no: string;
    USN_no: string;
    IndustryFacilitatorName: string;
    IndustryFacilitatorEmail: string;
    projectName: string;
    coreArea: string;
  };
  internshipDetails: {
    internshipPreferences: string;
    learningObjectives: string;
    technicalSkills: string;
    internshipActivities: string;
    evidenceToFaculty: string;
    startDate: Date;
    endDate: Date;
  };
  // Initialize other fields as needed
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  userProfile,
  isEditing,
  editProfile,
  handleSave,
  handleEdit,
  onProfileUpdate
}) => {
  const [currentProfile, setCurrentProfile] = useState<UserProfile | null>(userProfile);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState<File | null>(null);
  const [activeTable, setActiveTable] = useState<TableId | null>(null);
  const [preScreenings, setPreScreenings] = useState([]);

  const [activeForm, setActiveForm] = useState<FormId | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [facultyInfo, setFacultyInfo] = useState({
    hodName: '',
    hodEmail: '',
    facultyName: '',
    facultyEmail: '',
    facultyPhone: '',
    campusAddress: '',
    campusPhoneNumber: '',
    campusFaxNumber: '',
    schoolCityState: '',
    academicYear: '',
    schoolEmail: '',
  });

  const [formState, setFormState] = useState<AccountFormState>({
    userId: 0,
    facultyInfo: {
      hodName: '',
      hodEmail: '',
      facultyName: '',
      facultyEmail: '',
      facultyPhone: '',
      campusAddress: '',
      campusPhoneNumber: '',
      campusFaxNumber: '',
      schoolCityState: '',
      academicYear: '',
      schoolEmail: '',
    },
    industryInfo: {
      internshipCompany: '',
      USN_no: '',
      GST_no: '',
      IndustryFacilitatorName: '',
      IndustryFacilitatorEmail: '',
      projectName: '',
      coreArea: '',
    },
    internshipDetails: {
      internshipPreferences: '',
      learningObjectives: '',
      technicalSkills: '',
      internshipActivities: '',
      evidenceToFaculty: '',
      startDate: new Date(),
      endDate: new Date(),
    }
  });

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setFormState((prevFormState) => ({
            ...prevFormState,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);

  // State for Industry Facilitator form
  const [industryInfo, setIndustryInfo] = useState({
    internshipCompany: '',
     USN_no: '',
      GST_no: '',
    IndustryFacilitator: '',
    IndustryFacilitatorEmail: '',
    projectName: '',
    coreArea: '',
  });

  const handleFacultySave = async () => {
    try {
      console.log('Preparing to update faculty info:', formState);

      // Make an API call to update faculty info
      await updateFacultyInfo(formState.userId, formState.facultyInfo);

      // Optionally update the UI or state to reflect the changes
      alert('Faculty information updated successfully');
    } catch (error) {
      console.error('Error updating faculty information:', error);
      alert('Error updating faculty information');
    }
  };


  const handleIndustrySave = async () => {
    try {
      await updateIndustryFacilitatorInfo(formState.userId, formState.industryInfo);
      alert('Industry Facilitator information updated successfully');
    } catch (error) {
      alert('Error updating Industry Facilitator information');
    }
  };

  const handleInternshipSave = async () => {
    try {
      await createInitialInternshipDetails(
        formState.userId,
         formState.internshipDetails.internshipPreferences,
         formState.internshipDetails.learningObjectives,
         formState.internshipDetails.technicalSkills,
         formState.internshipDetails.internshipActivities,
         formState.internshipDetails.evidenceToFaculty,
         formState.internshipDetails.startDate,
         formState.internshipDetails.endDate
      );
      alert('Internship details created successfully');
    } catch (error) {
      alert('Error creating internship details');
    }
  };

  const showTable = (tableId: TableId) => {
    console.log("Show table:", tableId);  // Debug log for button click
    setActiveTable(tableId);
    setActiveForm(null);  // Hide the form if table is active
  };

  const showPopupForm = (formId: FormId) => {
    console.log("Show form:", formId);  // Debug log for button click
    setActiveForm(formId);
    setActiveTable(null);  // Hide the table if form is active
  };

  const hidePopup = () => {
    console.log("Hide popup");  // Debug log for hiding the popup
    setActiveTable(null);
    setActiveForm(null);
  };

  const saveForm = () => {
    alert('Form Saved!');
    hidePopup();
  };

  const [internshipDetails, setInternshipDetails] = useState({
    internshipPreferences: '',
    learningObjectives: '',
    technicalSkills: '',
    internshipActivities: '',
    evidenceToFaculty: '',
    startDate: new Date(),
    endDate: new Date(),
  });

 const [formData, setFormData] = useState({
  tenthBoard: '',
  tenthYear: '',
  tenthPercentage: '',
  twelfthBoard: '',
  twelfthYear: '',
  twelfthPercentage: '',
  degreeUniversity: '',
  degreeLastSemResult: '',
  userId: '',
  modelId: '',
   tenthDocumentURL: '',
    twelfthDocumentURL: '',
    degreeResultFileURL: '',
    videoURL: '',
    resumeURL: '',
    coverletterURL: '',
   isSprint: false,// Add this line to include cover letter URL
});

  const [files, setFiles] = useState({
    tenthDocumentURL: null as File | null,
    twelfthDocumentURL: null as File | null,
    degreeResultFileURL: null as File | null,
    videoURL: null as File | null,
    resumeURL: null as File | null,
    coverletterURL: null as File | null,
  });

  const [errors, setErrors] = useState({
    tenthDocumentURL: '',
    twelfthDocumentURL: '',
    degreeResultFileURL: '',
    videoURL: '',
    resumeURL: '',
    coverletterURL: '',
  });

  const [models, setModels] = useState<Array<{ id: number; name: string }>>([]);
  const [submissionMessage, setSubmissionMessage] = useState('');

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID'); // Retrieve IIMSTC_ID from local storage
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setFormData((prevFormData) => ({
            ...prevFormData,
            userId: response.data.userId, // Set userId from response
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    // Fetch internship models from the API
    const fetchModels = async () => {
      try {
        const response = await axios.get('/api/internship-model/all'); // Adjust API endpoint as needed
        setModels(response.data);
      } catch (error) {
        console.error('Error fetching internship models:', error);
      }
    };

    fetchModels();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value, type } = e.target;

  // Handle checkbox input separately
  const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

  setFormData({
    ...formData,
    [name]: newValue
  });
};

 const handleRange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
  const { name, type } = e.target;

  // Determine the new value based on the input type
  let newValue: string | boolean;

  if (type === 'checkbox') {
    // Type assertion for checkbox
    newValue = (e.target as HTMLInputElement).checked;
  } else {
    // For text and select inputs
    newValue = (e.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).value;
  }

  // Update the state based on the field name
  if (name in formState.facultyInfo) {
    setFormState((prevState) => ({
      ...prevState,
      facultyInfo: {
        ...prevState.facultyInfo,
        [name]: newValue,
      },
    }));
  } else if (name in formState.industryInfo) {
    setFormState((prevState) => ({
      ...prevState,
      industryInfo: {
        ...prevState.industryInfo,
        [name]: newValue,
      },
    }));
      } else if (name in formState. internshipDetails) {
    setFormState((prevState) => ({
      ...prevState,
      internshipDetails: {
        ...prevState. internshipDetails,
        [name]: newValue,
      }, 
    }));
  }
};
 const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked); // Update state with the checkbox value
  };



  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: files[0]
      }));
    }
  };
useEffect(() => {
    const fetchUserData = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const userResponse = await axios.get(
            `/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`
          );
          const userId = userResponse.data.userId;

          setFormState((prevFormState) => ({ ...prevFormState, userId }));
          setFormData((prevFormData) => ({ ...prevFormData, userId }));

          // Fetch all required data in parallel
          const [
            facultyData,
            industryData,
            internshipData,
            preScreeningData,
          ] = await Promise.all([
            getFacultyInfo(userId),
            getIndustryFacilitatorInfo(userId),
            getInitialInternshipDetails(userId),
            axios.get(`/api/prescreening/${userId}`), // Fetch pre-screening records
          ]);

          setFormState((prevFormState) => ({
            ...prevFormState,
            facultyInfo: facultyData,
            industryInfo: industryData,
            internshipDetails: internshipData,
          }));

          // Update preScreenings state with the fetched data
          setPreScreenings(preScreeningData.data.preScreenings || []);

          // Set formData with education details from prescreening data
          const preScreeningsArray = preScreeningData.data.preScreenings || [];
          if (preScreeningsArray.length > 0) {
            const preScreeningRecord = preScreeningsArray[0];

            setFormData((prevFormData) => ({
              ...prevFormData,
              tenthBoard: preScreeningRecord.tenthBoard || '',
              tenthYear: preScreeningRecord.tenthYear || '',
              tenthPercentage: preScreeningRecord.tenthPercentage || '',
              twelfthBoard: preScreeningRecord.twelfthBoard || '',
              twelfthYear: preScreeningRecord.twelfthYear || '',
              twelfthPercentage: preScreeningRecord.twelfthPercentage || '',
              degreeUniversity: preScreeningRecord.degreeUniversity || '',
              degreeLastSemResult: preScreeningRecord.degreeLastSemResult || '',
              coverletterURL:preScreeningRecord.coverletterURL || '',
              tenthDocumentURL:preScreeningRecord.tenthDocumentURL || '',
    twelfthDocumentURL:preScreeningRecord.twelfthDocumentURL || '',
    degreeResultFileURL: preScreeningRecord.degreeResultFileURL || '',
    videoURL: preScreeningRecord.videoURL || '',
    resumeURL: preScreeningRecord.resumeURL || '',
    isSprint: preScreeningRecord.isSprint || '',
              
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserData();
  }, []);



  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, modelId: e.target.value });
  };

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const formDataToSubmit = new FormData();
  
Object.keys(formData).forEach((key) => {
  const value = formData[key as keyof typeof formData];
  
  // Convert boolean to string for submission
  if (typeof value === 'boolean') {
    formDataToSubmit.append(key, value ? 'true' : 'false');
  } else {
    formDataToSubmit.append(key, value);
  }
});



  // Handling file uploads
  Object.keys(files).forEach((key) => {
    const file = files[key as keyof typeof files];
    if (file) {
      formDataToSubmit.append(key, file);
    }
  });

  try {
    const response = await axios.post('/api/prescreening/create', formDataToSubmit, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('API Response:', response.data);
    setSubmissionMessage('We will get back to you soon! Thank you for submitting your pre-screening form.');

    if (response.data && response.data.message) {
      alert('Form submitted successfully!');

      if (response.data.modelId) {
        localStorage.setItem('modelId', response.data.modelId);
      }

      // Reset form and file states
     setFormData({
  tenthBoard: '',
  tenthYear: '',
  tenthPercentage: '',
  twelfthBoard: '',
  twelfthYear: '',
  twelfthPercentage: '',
  degreeUniversity: '',
  degreeLastSemResult: '',
  userId: '',
  isSprint: false,  // Remain boolean
  modelId: '',
  tenthDocumentURL: '',
  twelfthDocumentURL: '',
  degreeResultFileURL: '',
  videoURL: '',
  resumeURL: '',
  coverletterURL: '',
});

      setFiles({
        tenthDocumentURL: null,
        twelfthDocumentURL: null,
        degreeResultFileURL: null,
        videoURL: null,
        resumeURL: null,
        coverletterURL: null,
      });

      setErrors({
        tenthDocumentURL: '',
        twelfthDocumentURL: '',
        degreeResultFileURL: '',
        videoURL: '',
        resumeURL: '',
        coverletterURL: '',
      });
    } else {
      alert('Unexpected response format. Please try again.');
    }
  } catch (error) {
    console.error('Error creating PreScreening:', error);
    alert('Error submitting form. Please try again.');
  }
};

  useEffect(() => {
    setActiveTable('student-detail');
  }, []);




  const [enrollChecked, setEnrollChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleEnrollChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnrollChecked(e.target.checked);
    setShowPopup(e.target.checked); 
    const { name, value, type } = e.target;

  // Handle checkbox input separately
  const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

  setFormData({
    ...formData,
    [name]: newValue
  });
  };


  const handlePopupYes = () => {
    // Handle the confirmation logic here
    setShowPopup(false);
  };

  const handlePopupCancel = () => {
    setEnrollChecked(false); 
    setShowPopup(false);     
  };










  return (
    <div className="std-main-container">
      {/* Cards */}
      <h2 className="text-3xl  ml-1 mt-5 mb-5 font-bold">User Profile</h2>
      <div className="std-card-container">
        <div className="std-card">
          <h3>About Me</h3>
          <i className="fas fa-eye std-view-icon" onClick={() => showTable('student-detail')}></i>

        </div>
        <div className="std-card">
          <h3>Education Details</h3>
          <i className="fas fa-eye std-view-icon" onClick={() => showTable('education-info')}></i>
          <i className="fas fa-pen std-edit-icon" onClick={() => showPopupForm('education-info-form')}></i>
        </div>
        <div className="std-card">
          <h3>Internship Details</h3>
          <i className="fas fa-eye std-view-icon" onClick={() => showTable('internship-details')}></i>
          <i className="fas fa-pen std-edit-icon" onClick={() => showPopupForm('internship-details-form')}></i>
        </div>
        <div className="std-card">
          <h3>Faculty Details</h3>
          <i className="fas fa-eye std-view-icon" onClick={() => showTable('faculty-info')}></i>
          <i className="fas fa-pen std-edit-icon" onClick={() => showPopupForm('faculty-info-form')}></i>
        </div>
        <div className="std-card">
          <h3>Industry Details</h3>
          <i className="fas fa-eye std-view-icon" onClick={() => showTable('industry-facilitator')}></i>
          <i className="fas fa-pen std-edit-icon" onClick={() => showPopupForm('industry-facilitator-form')}></i>
        </div>


      </div>

      {/* Popup Tables - Conditional rendering based on activeTable */}
      {activeTable && (
        <div className="std-popup-table" onClick={hidePopup}>
          <div className="std-table-content" onClick={(e) => e.stopPropagation()}>
            {activeTable === 'student-detail' && (
              <>
                <h3>About Me</h3>
                {currentProfile && (
                  <React.Fragment>
                    <table className="std-table-data">
                      <tbody>

                        <tr>
                          <td colSpan={3}><strong>Name: </strong>&nbsp;{currentProfile.name}</td>


                          


                        </tr>
                        <tr >
                          <td colSpan={2}><strong>College Name:</strong>&nbsp;{currentProfile.collegeDetails.name}</td>
                          <td><strong>Semester:</strong>&nbsp; {currentProfile.semester}</td>

                        </tr>
                        <tr>


                          <td><strong>Aadhar :</strong>&nbsp; {currentProfile.aadharNo}</td>

                          <td><strong>Email ID:</strong>&nbsp; {currentProfile.email}</td>

                          <td><strong>Phone:</strong>&nbsp; {currentProfile.phoneNumber}</td>

                        </tr>
                        <tr>

                          <td colSpan={3}>
                            <strong>Address:</strong>&nbsp;{currentProfile.address}
                          </td>

                        </tr>

                        <tr>
                          <td colSpan={3}><strong>University:</strong>&nbsp; {currentProfile.university}</td>
                        </tr>
                        <tr>
                          <td><strong>Degree Status:</strong>&nbsp; {currentProfile.degreeStatusDetails?.StatusName || ''}</td>

                          <td colSpan={2}><strong>Degree:</strong>&nbsp;{currentProfile.degreeDetails?.DegreeName || ''}</td>
                        </tr>
                        <tr>
                          <td><strong>Branch:</strong>&nbsp;{currentProfile.branchDetails?.BranchName || ''}</td>
                          <td><strong>Semester:</strong>&nbsp;{currentProfile.semester}</td>
                          <td> <strong>USN:</strong>&nbsp; {currentProfile.usn}</td>
                        </tr>
                        <tr>


                        </tr>



                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </>
            )}
            {activeTable === 'education-info' && (
              <>
                <h3><strong>Education Details</strong>&nbsp;</h3>
                <table className="std-table-data">
  <tbody>
    <tr>
      <td><strong>10th Board:</strong>&nbsp;{formData.tenthBoard}</td>
      <td><strong>10th Passing Year:</strong>&nbsp;{formData.tenthYear}</td>
      <td><strong>10th Percentage:</strong>&nbsp;{formData.tenthPercentage}</td>
    </tr>
    
    <tr>
      <td colSpan={3}>
        <strong>10th Marks Card: </strong>&nbsp;
        {formData.tenthDocumentURL && (
          <a href={formData.tenthDocumentURL} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )}
      </td>
    </tr>
    
    <tr>
      <td colSpan={3}><strong>12th Board:</strong>&nbsp;{formData.twelfthBoard}</td>
    </tr>
    
    <tr>
      <td><strong>12th Passing Year:</strong>&nbsp;{formData.twelfthYear}</td>
      <td colSpan={2}><strong>12th Percentage:</strong>&nbsp;{formData.twelfthPercentage}</td>
    </tr>
    
    <tr>
      <td colSpan={3}>
        <strong>12th Marks Card: </strong>&nbsp;
        {formData.twelfthDocumentURL && (
          <a href={formData.twelfthDocumentURL} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )}
      </td>
    </tr>
    
    <tr>
      <td>
        <strong>Resume:</strong>&nbsp;
        {formData.resumeURL && (
          <a href={formData.resumeURL} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )}
      </td>
      <td colSpan={2}>
        <strong>Cover Letter:</strong>&nbsp;
        {formData.coverletterURL && (
          <a href={formData.coverletterURL} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )}
      </td>
    </tr>
    
    <tr>
      <td colSpan={3}>
        <strong>1.5 Mins Self Introduction Video:</strong>&nbsp;
        {formData.videoURL && (
          <a href={formData.videoURL} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )}
      </td>
    </tr>
  </tbody>
</table>

              </>
            )}
            <>
              {activeTable === 'faculty-info' && (
                <>
                  <h3>Faculty Details</h3>
                  <table className="std-table-data">
                    <tbody>
                      <tr>
                        <td colSpan={3}><strong>Head of Department (HOD) Name:</strong>&nbsp; {formState.facultyInfo.hodName}</td>
                      </tr>
                      <tr>
                        <td><strong>HOD Email Address:</strong>&nbsp; {formState.facultyInfo.hodEmail}</td>
                        <td><strong>Faculty Name:</strong>&nbsp; {formState.facultyInfo.facultyName}</td>
                        <td><strong>Faculty Email: </strong>&nbsp;{formState.facultyInfo.facultyEmail}</td>
                      </tr>
                      <tr>
                        <td><strong>Department:</strong>&nbsp; {formState.facultyInfo.schoolCityState}</td>
                        <td><strong>Phone:</strong>&nbsp; {formState.facultyInfo.facultyPhone}</td>
                        <td><strong>Fax:</strong>&nbsp; {formState.facultyInfo.campusFaxNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              {activeTable === 'industry-facilitator' && (
                <>
                  <h3>Industry Details</h3>
                  <table className="std-table-data">
                    <tbody>
                      <tr>
                        <td colSpan={3}><strong>Industry Name:</strong>&nbsp; {formState.industryInfo.internshipCompany}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}><strong>Industry Mentor Name:</strong>&nbsp; {formState.industryInfo.IndustryFacilitatorName}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}><strong>GST No:</strong>&nbsp; {formState.industryInfo.GST_no}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}><strong>UAN No:</strong>&nbsp; {formState.industryInfo.USN_no}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}><strong>Industry Mentor Official Email ID:</strong>&nbsp; {formState.industryInfo.IndustryFacilitatorEmail}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}><strong>Project Name:</strong>&nbsp; {formState.industryInfo.projectName}</td>
                        <td><strong>Core Area:</strong>&nbsp; {formState.industryInfo.coreArea}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </>

            {activeTable === 'internship-details' && (
               <>
    <h3>Internship Details</h3>
    <table className="std-table-data">
      <tbody>
        <tr>
          <td colSpan={3}>
            <strong>Internship Preferences:</strong>&nbsp;
            {formState.internshipDetails.internshipPreferences || 'Not Available'}
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <strong>Learning Objectives:</strong>&nbsp;
            {formState.internshipDetails.learningObjectives || 'Not Available'}
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <strong>Technical Skills:</strong>&nbsp;
            {formState.internshipDetails.technicalSkills || 'Not Available'}
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <strong>Internship Activities:</strong>&nbsp;
            {formState.internshipDetails.internshipActivities || 'Not Available'}
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <strong>Evidence to Faculty:</strong>&nbsp;
            {formState.internshipDetails.evidenceToFaculty || 'Not Available'}
          </td>
        </tr>
      </tbody>
    </table>
  </>
            )}
          </div>
        </div>
      )}

      {/* Popup Forms - Conditional rendering based on activeForm */}
      {activeForm && (

        <div className="std-form-container">
          <h3>{activeForm.replace('-form', '').replace(/-/g, ' ').toUpperCase()}</h3>

          {activeForm === 'personal-details-form' && (
            <form>
              <div className="std-form-field">
                <label htmlFor="name">Name:</label>
                <input id="name" type="text" required />
              </div>

              <div className="std-form-field">
                <label htmlFor="dob">Date of Birth:</label>
                <input id="dob" type="date" required />
              </div>

              <div className="std-form-field">
                <label htmlFor="address">Address:</label>
                <textarea id="address" rows={4} required></textarea>
              </div>

              <div className="std-form-field">
                <label htmlFor="phone">Phone:</label>
                <input id="phone" type="tel" required />
              </div>

              <div className="std-form-field">
                <label htmlFor="email">Email:</label>
                <input id="email" type="email" required />
              </div>

              <div className="std-form-field">
                <label htmlFor="photo">Upload Photo:</label>
                <input id="photo" type="file" accept="image/*" required />
              </div>

              <div className="std-form-actions">
                <button type="button" onClick={saveForm}>Save</button>
                <button type="button" onClick={hidePopup}>Cancel</button>
              </div>
            </form>
          )}

          {activeForm === 'education-info-form' && (
            <>
              <form onSubmit={handleSubmit}>
                
                <div className="std-form-field">
                  <label htmlFor="tenthBoard">10th board School Name:</label>
                  <select
                    id="tenthBoard"
                    name="tenthBoard"
                    value={formData.tenthBoard}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="">Select Board</option>
                    <option value="IB">IB</option>
                    <option value="IGCSE">IGCSE</option>
                    <option value="CBSE">CBSE</option>
                    <option value="ICSE">ICSE</option>
                    <option value="State">State</option>
                  </select>
                </div>


                <div className="std-form-field">
                  <label htmlFor="email">10th Passing Year:</label>
                  <input
                    type="text"
                    id="tenthYear"
                    name="tenthYear"
                    value={formData.tenthYear}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>


                <div className="std-form-field">
                  <label htmlFor="email">10th Percentage: </label>
                  <input
                    type="text"
                    id="tenthPercentage"
                    name="tenthPercentage"
                    value={formData.tenthPercentage}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="signature">10th Document</label>
                  <input
                    type="file"
                    id="tenthDocument"
                    name="tenthDocument"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />

                </div>


                <div className="std-form-field">
                  <label htmlFor="email">12th Board College Name:  </label>
                  <select
                    id="twelfthBoard"
                    name="twelfthBoard"
                    value={formData.twelfthBoard}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="">Select Board</option>
                    <option value="IB">IB</option>
                    <option value="IGCSE">IGCSE</option>
                    <option value="CBSE">CBSE</option>
                    <option value="ICSE">ICSE</option>
                    <option value="State">State</option>
                  </select>

                </div>


                <div className="std-form-field">
                  <label htmlFor="email">12th Board College Name:</label>
                  <input
                    type="text"
                    id="twelfthYear"
                    name="twelfthYear"
                    value={formData.twelfthYear}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>



                <div className="std-form-field">
                  <label htmlFor="email">12th Percentage  </label>
                  <input
                    type="text"
                    id="twelfthPercentage"
                    name="twelfthPercentage"
                    value={formData.twelfthPercentage}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="signature">12th Document</label>
                  <input
                    type="file"
                    id="twelfthDocument"
                    name="twelfthDocument"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />

                </div>
                <div className="std-form-field">
                  <label htmlFor="email">Degree University/College: </label>
                  <input
                    type="text"
                    id="degreeUniversity"
                    name="degreeUniversity"
                    value={formData.degreeUniversity}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="phone">Degree CGPA:</label>
                  <input
                    type="text"
                    id="degreeLastSemResult"
                    name="degreeLastSemResult"
                    value={formData.degreeLastSemResult}
                    onChange={handleChange}

                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="signature">Degree Result File</label>
                  <input
                    type="file"
                    id="degreeResultFile"
                    name="degreeResultFile"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />

                </div>








                <div className="std-form-field">
                  <label htmlFor="signature">Resume:</label>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="signature">Cover Letter:</label>
                  <input
                    type="file"
                    id="coverletter"
                    name="coverletter"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />
                </div>


                <div className="std-form-field">
                  <label htmlFor="signature">1.5 min self Introduction Video:</label>
                  <input
                    type="file"
                    id="video"
                    name="video"
                    onChange={handleFileChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="email">Select Model  </label>
                  <select
                    id="modelId"
                    name="modelId"
                    value={formData.modelId}
                    onChange={handleModelChange}
                    className="w-full p-3 border rounded-lg shadow-sm"
                  >
                    <option value="">Select Model</option>
                    {models
                      .map(model => (
                        <option key={model.id} value={model.id}>
                          {model.name}
                        </option>
                      ))}
                  </select>
                </div>
               <div className="std-form-field">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      id="isSprint"
                      name="isSprint"
                      checked={enrollChecked}
                      onChange={handleEnrollChange}
                    />
                    <label htmlFor="isSprint">Enroll for Internship Program</label>
                  </div>
                  {/* Render Popup conditionally */}
                  {showPopup && (
                    <div className="popup-overlay fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                      <div className="popup-content bg-white p-6 rounded-lg shadow-lg">
                        <p className="text-black">Are you sure you want to take the International Internship Program?</p>
                        <div className="popup-buttons mt-4">
                          <button
                            type="button"
                            onClick={handlePopupYes}
                            className="px-4 py-2 bg-green-500 text-white rounded-lg mr-2"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            onClick={handlePopupCancel}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
   


                <div className="std-form-buttons">
                  <button className="std-save-btn"
                    type="submit"
                    
                  >
                    Submit
                  </button>
                  {submissionMessage && (
                    <div className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded-lg mb-4">
                      {submissionMessage}
                    </div>
                  )}
                  <button type="button" className="std-back-btn" onClick={hidePopup}>Back</button>
                </div>

              </form>
            </>
          )}
          {activeForm === 'faculty-info-form' && (
            <form>
              <div className="std-form-field">
                <label htmlFor="hodName">Head of the Department (HOD) Name:</label>
                <input
                  type="text"
                  name="hodName"
                  value={formState.facultyInfo.hodName}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="hodEmail">HOD Email Address:</label>
                <input

                  type="text"
                  name="hodEmail"
                  value={formState.facultyInfo.hodEmail}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="facultyName">Faculty Name:</label>
                <input
                  name="facultyName"
                  type="text"
                  required
                  value={formState.facultyInfo.facultyName}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="facultyEmail">Faculty Email:</label>
                <input
                  name="facultyEmail"
                  type="text"
                  required
                  value={formState.facultyInfo.facultyEmail}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="facultyPhone">Phone Number:</label>
                <input
                  name="facultyPhone"
                  type="text"
                  required
                  value={formState.facultyInfo.facultyPhone}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="campusAddress">Campus Address:</label>
                <input
                  name="campusAddress"
                  type="text"
                  required
                  value={formState.facultyInfo.campusAddress}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="campusPhoneNumber">Campus Phone Number:</label>
                <input
                  name="campusPhoneNumber"
                  type="tel"
                  required
                  value={formState.facultyInfo.campusPhoneNumber}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="campusFaxNumber">FAX:</label>
                <input
                  name="campusFaxNumber"
                  type="tel"
                  required
                  value={formState.facultyInfo.campusFaxNumber}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="schoolCityState">School City/State:</label>
                <input
                  name="schoolCityState"
                  type="text"
                  required
                  value={formState.facultyInfo.schoolCityState}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="academicYear">Academic Year:</label>
                <input
                  name="academicYear"
                  type="text"
                  required
                  value={formState.facultyInfo.academicYear}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-field">
                <label htmlFor="schoolEmail">School Email:</label>
                <input
                  name="schoolEmail"
                  type="email"
                  required
                  value={formState.facultyInfo.schoolEmail}
                  onChange={handleRange}
                />
              </div>

              <div className="std-form-buttons">
                <button
                  type="button"
                  className="std-save-btn"
                  onClick={handleFacultySave}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="std-back-btn"
                  onClick={() => setActiveForm(null)}
                >
                  Back
                </button>
              </div>
            </form>
          )}

          {/* Industry Facilitator Form */}
          {activeForm === 'industry-facilitator-form' && (
            <>
              <form>
                <div className="std-form-field">
                  <label htmlFor="internshipCompany">Industry Name:</label>
<select
  name="internshipCompany"
  value={formState.industryInfo.internshipCompany}
  onChange={handleRange}
>
  <option value="">Select a Company</option>
  <option value="Infosys">Infosys</option>
  <option value="Wipro">Wipro</option>
  <option value="TCS">TCS</option>
  <option value="Mindtree">Mindtree</option>
  <option value="Mphasis">Mphasis</option>
  <option value="HCL Technologies">HCL Technologies</option>
  <option value="Tech Mahindra">Tech Mahindra</option>
  <option value="IBM India">IBM India</option>
  <option value="Accenture">Accenture</option>
  <option value="Capgemini">Capgemini</option>
  <option value="Cognizant">Cognizant</option>
  <option value="Oracle India">Oracle India</option>
  <option value="SAP Labs India">SAP Labs India</option>
  <option value="Dell Technologies">Dell Technologies</option>
  <option value="Intel India">Intel India</option>
  <option value="Microsoft India R&D">Microsoft India R&D</option>
  <option value="Cisco Systems India">Cisco Systems India</option>
  <option value="Google India">Google India</option>
  <option value="Amazon Web Services (AWS)">Amazon Web Services (AWS)</option>
  <option value="Flipkart">Flipkart</option>
  <option value="Byju's">Byju's</option>
  <option value="Ola Cabs">Ola Cabs</option>
  <option value="Swiggy">Swiggy</option>
  <option value="Zomato">Zomato</option>
  <option value="PhonePe">PhonePe</option>
  <option value="Udaan">Udaan</option>
  <option value="Meesho">Meesho</option>
  <option value="InMobi">InMobi</option>
  <option value="Practo">Practo</option>
  <option value="BigBasket">BigBasket</option>
  <option value="Razorpay">Razorpay</option>
  <option value="Freshworks">Freshworks</option>
  <option value="Mu Sigma">Mu Sigma</option>
  <option value="Quikr">Quikr</option>
  <option value="Unacademy">Unacademy</option>
  <option value="CureFit">CureFit</option>
  <option value="Zoomcar">Zoomcar</option>
  <option value="Urban Ladder">Urban Ladder</option>
  <option value="Lenskart">Lenskart</option>
  <option value="Dailyhunt">Dailyhunt</option>
  <option value="RedBus">RedBus</option>
  <option value="Dunzo">Dunzo</option>
  <option value="Paytm">Paytm (Bangalore Office)</option>
  <option value="Glance">Glance</option>
  <option value="CRED">CRED</option>
  <option value="Bounce">Bounce</option>
  <option value="Ather Energy">Ather Energy</option>
  <option value="Myntra">Myntra</option>
  <option value="Treebo Hotels">Treebo Hotels</option>
  <option value="Grofers">Grofers (Bangalore Office)</option>
  <option value="NetApp India">NetApp India</option>
  <option value="LinkedIn India">LinkedIn India</option>
  <option value="Adobe India">Adobe India</option>
  <option value="ThoughtWorks">ThoughtWorks</option>
  <option value="Zeta">Zeta</option>
  <option value="HackerRank">HackerRank</option>
  <option value="Exotel">Exotel</option>
  <option value="Simplilearn">Simplilearn</option>
  <option value="MoEngage">MoEngage</option>
  <option value="Dream11">Dream11 (Bangalore Office)</option>
  <option value="ShareChat">ShareChat</option>
  <option value="Gojek India">Gojek India</option>
  <option value="Flock">Flock</option>
  <option value="Postman">Postman</option>
  <option value="Dunnhumby India">Dunnhumby India</option>
  <option value="Clevertap">Clevertap</option>
  <option value="Belong.co">Belong.co</option>
  <option value="Livspace">Livspace</option>
  <option value="Verloop">Verloop</option>
  <option value="Ninjacart">Ninjacart</option>
  <option value="Happay">Happay</option>
  <option value="Enquero">Enquero</option>
  <option value="Bounce">Bounce</option>
  <option value="Upstox">Upstox</option>
  <option value="Wingify">Wingify</option>
  <option value="Locus.sh">Locus.sh</option>
  <option value="SigTuple">SigTuple</option>
  <option value="GreyOrange">GreyOrange</option>
  <option value="Vymo">Vymo</option>
  <option value="Capillary Technologies">Capillary Technologies</option>
  <option value="Ezetap">Ezetap</option>
  <option value="Innovaccer">Innovaccer</option>
  <option value="Manthan">Manthan</option>
  <option value="Vedantu">Vedantu</option>
  <option value="SmartQ">SmartQ</option>
  <option value="Razorpay">Razorpay</option>
  <option value="NextGen Healthcare">NextGen Healthcare</option>
  <option value="FreshMenu">FreshMenu</option>
  <option value="TapChief">TapChief</option>
  <option value="KreditBee">KreditBee</option>
  <option value="Goomo">Goomo</option>
  <option value="Tracxn">Tracxn</option>
  <option value="PlaySimple Games">PlaySimple Games</option>
  <option value="Belong.co">Belong.co</option>
  <option value="Rapido">Rapido</option>
  <option value="Drivezy">Drivezy</option>
  <option value="DocsApp">DocsApp</option>
  <option value="Kissht">Kissht</option>
  <option value="Furlenco">Furlenco</option>
  <option value="HealthifyMe">HealthifyMe</option>
</select>
<div style={{ display: 'flex', alignItems: 'center' }}>
      <label 
        htmlFor="vtuApproved" 
        style={{ 
          marginRight: '10px', 
          whiteSpace: 'nowrap' // Prevent label text from wrapping
        }}
      >
        Approved by VTU
      </label>
      <input
        type="checkbox"
        id="vtuApproved"
        name="vtuApproved"
        checked={isChecked} // Sync checkbox with state
        onChange={handleCheckboxChange} // Handle state update on change
      />
    </div>

            </div>
                  <div className="std-form-field">
                  <label htmlFor="GST_no">GST No:</label>
                  <input
                    name="GST_no"
                    type="text"
                    required
                    value={formState.industryInfo.GST_no}
                    onChange={handleRange}
                  />
                </div>
                <div className="std-form-field">
                  <label htmlFor="USN_no">UAN No:</label>
                  <input
                    name="USN_no"
                    type="text"
                    required
                    value={formState.industryInfo.USN_no}
                    onChange={handleRange}
                  />
                </div>
                

                <div className="std-form-field">
  <label htmlFor="IndustryFacilitatorName">Industry Mentor Name:</label>
  <input
    name="IndustryFacilitatorName" // Match this exactly with the key in industryInfo
    type="text"
    required
    value={formState.industryInfo.IndustryFacilitatorName}
    onChange={handleRange}
  />
</div>

<div className="std-form-field">
  <label htmlFor="IndustryFacilitatorEmail">Industry Mentor Official Email ID:</label>
  <input
    name="IndustryFacilitatorEmail" // Match this exactly with the key in industryInfo
    type="email"
    required
    value={formState.industryInfo.IndustryFacilitatorEmail}
    onChange={handleRange}
  />
</div>

                <div className="std-form-field">
                  <label htmlFor="projectName">Project Name:</label>
                  <input
                    name="projectName"
                    type="text"
                    required
                    value={formState.industryInfo.projectName}
                    onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="coreArea">Core Area:</label>
                  <input
                    name="coreArea"
                    type="text"
                    required
                    value={formState.industryInfo.coreArea}
                    onChange={handleRange}
                  />
                </div>

                <div className="std-form-buttons">
                  <button type="button" className="std-save-btn" onClick={handleIndustrySave}>
                    Save
                  </button>
                  <button type="button" className="std-back-btn" onClick={() => setActiveForm(null)}>
                    Back
                  </button>
                </div>
              </form>
            </>
          )}

          {/* Internship Details Form */}
          {activeForm === 'internship-details-form' && (
            <>
              <form>
                <div className="std-form-field">
                  <label htmlFor="internshipPreferences">Internship Preferences:</label>
                  <textarea
                    id="internshipPreferences"
                    required
                    name="internshipPreferences"
                    value={formState.internshipDetails.internshipPreferences}
                  onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="learningObjectives">Learning Objectives:</label>
                  <textarea
                    id="learningObjectives"
                    required
                    name="learningObjectives"
                    value={formState.internshipDetails.learningObjectives}
                    onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="technicalSkills">Technical Skills:</label>
                  <textarea
                    id="technicalSkills"
                    required
                    name="technicalSkills"
                    value={formState.internshipDetails.technicalSkills}
onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="internshipActivities">Internship Activities:</label>
                  <textarea
                    id="internshipActivities"
                    required
                     name="internshipActivities"
                    value={formState.internshipDetails.internshipActivities}
                   onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="evidenceToFaculty">Evidence Submitted to Faculty:</label>
                  <textarea
                    id="evidenceToFaculty"
                    required
                    name="evidenceToFaculty"
                    value={formState.internshipDetails.evidenceToFaculty}
                    onChange={handleRange}
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="startDate">Internship Start Date:</label>
                  <input
                    id="startDate"
                    type="date"
                    required
                    value={internshipDetails.startDate.toISOString().slice(0, 10)}
                    onChange={(e) =>
                      setInternshipDetails({
                        ...internshipDetails,
                        startDate: new Date(e.target.value),
                      })
                    }
                  />
                </div>

                <div className="std-form-field">
                  <label htmlFor="endDate">Internship End Date:</label>
                  <input
                    id="endDate"
                    type="date"
                    required
                    value={internshipDetails.endDate.toISOString().slice(0, 10)}
                    onChange={(e) =>
                      setInternshipDetails({
                        ...internshipDetails,
                        endDate: new Date(e.target.value),
                      })
                    }
                  />
                </div>

                <div className="std-form-buttons">
                  <button type="button" className="std-save-btn" onClick={handleInternshipSave}>
                    Save
                  </button>
                  <button type="button" className="std-back-btn" onClick={() => setActiveForm(null)}>
                    Back
                  </button>
                </div>
              </form>
            </>
          )}

        </div>
      )}
    </div>
  );
};

export default AccountDetails;
