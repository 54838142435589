import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate,  Link  } from 'react-router-dom';
import axios from 'axios';
import './css-styles/form.css';

import {
  completeRegistration,
  fetchColleges,
  fetchDegrees,
  fetchBranches,
  fetchBranchesByDegree,
  fetchDegreeStatusesByDegree,
} from '../services/userService';

interface FormData {
  email: string;
  name: string;
  dob: string;
  otp: string;
  address: string;
  collegeId: string;
  degreeId: string;
  branchId: string;
  textbook: string;
  degreeStatusId: string;
  university: string;
  usn: string;
  aadharNo: string;
  gender: string;
  semester: string;
  phoneNumber: string;
  passportPhoto: File | null;
  aadharProof: File | null;
  pageSource: string; 
  customCollegeName?: string;// New field to track page source
}


interface College {
  id: string;
  name: string;
}

interface Degree {
  id: string;
  name: string;
}

interface Branch {
  id: string;
  name: string;
}

interface DegreeStatus {
  id: string;
  name: string;
}

const Form = ({ pageSource }: { pageSource: string }) => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
    name: '',
    otp: '',
    dob: '',
    address: '',
    collegeId: '',
    degreeId: '',
    branchId: '',
    degreeStatusId: '',
    university: '',
    usn: '',
    aadharNo: '',
    gender: '',
    semester: '',
    phoneNumber: '',
    textbook: '',
    passportPhoto: null,
    aadharProof: null,
    pageSource: pageSource,
    customCollegeName: '', 
    // Set the pageSource when the form loads
  });


  const [colleges, setColleges] = useState<{ id: string; name: string }[]>([]);
  const [degrees, setDegrees] = useState<{ DegreeName: ReactNode; id: string; name: string }[]>([]);
  const [branches, setBranches] = useState<{ BranchName: ReactNode; id: string; name: string }[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<{ BranchName: ReactNode; BranchID: string; name: string }[]>([]);
  const [degreeStatuses, setDegreeStatuses] = useState<{ DegreeStatusID: string; StatusName: string }[]>([]);
  
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const semesters = [
    { id: '1', name: 'Semester 1' },
    { id: '2', name: 'Semester 2' },
    { id: '3', name: 'Semester 3' },
    { id: '4', name: 'Semester 4' },
    { id: '5', name: 'Semester 5' },
    { id: '6', name: 'Semester 6' },
    { id: '7', name: 'Semester 7' },
    { id: '8', name: 'Semester 8' }
];

  const [validity, setValidity] = useState({
    name: true,
    dob: true,
    address: true,
    collegeId: true,
    degreeId: true,
    branchId: true,
    degreeStatusId: true,
    university: true,
    usn: true,
    aadharNo: true,
    gender: true,
    semester: true,
    phoneNumber: true,
    passportPhoto: true,
    aadharProof: true,
    email: true,
  });

  useEffect(() => {
    localStorage.clear();

    fetchColleges()
      .then((data) => {
        if (Array.isArray(data)) {
          const sortedColleges = data.sort((a, b) => a.name.localeCompare(b.name));
          setColleges(sortedColleges);
        } else {
          console.error('Unexpected response structure:', data);
        }
      })
      .catch((error) => console.error('Error fetching colleges:', error));

    fetchDegrees()
      .then((data) => {
        if (Array.isArray(data)) {
          setDegrees(data);
        } else {
          console.error('Unexpected response structure:', data);
        }
      })
      .catch((error) => console.error('Error fetching degrees:', error));

    fetchBranches()
      .then((data) => {
        if (Array.isArray(data)) {
          setBranches(data);
        } else {
          console.error('Unexpected response structure:', data);
        }
      })
      .catch((error) => console.error('Error fetching branches:', error));
  }, []);

  useEffect(() => {
    if (formData.degreeId) {
      fetchBranchesByDegree(formData.degreeId)
        .then((data) => {
          if (Array.isArray(data)) {
            setFilteredBranches(data);
          } else {
            console.error('Unexpected response structure:', data);
          }
        })
        .catch((error) => console.error('Error fetching branches:', error));

      fetchDegreeStatusesByDegree(formData.degreeId)
        .then((data) => {
          if (Array.isArray(data)) {
            setDegreeStatuses(data);
          } else {
            console.error('Unexpected response structure:', data);
          }
        })
        .catch((error) => console.error('Error fetching degree statuses:', error));
    } else {
      setFilteredBranches([]);
      setDegreeStatuses([]);
    }
  }, [formData.degreeId]);

  const handleChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
) => {
  const { name, value } = e.target;

  if (e.target instanceof HTMLInputElement) {
    // Handle file input separately
    if (e.target.type === 'file') {
      const files = e.target.files;
      setFormData(prevData => ({
        ...prevData,
        [name]: files ? files[0] : null,
      }));
    } else {
      // Date validation logic for 'dob' field
      if (name === 'dob') {
        const selectedDate = new Date(value);
        const year = selectedDate.getFullYear();

        if (year > 2007) {
          setValidity(prevValidity => ({
            ...prevValidity,
            dob: false,
          }));
          setErrorMessage('Please enter a year on or before 2007.');
        } else {
          setValidity(prevValidity => ({
            ...prevValidity,
            dob: true,
          }));
          setErrorMessage('');
        }
      }

      // Handle other input types
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  } else if (e.target instanceof HTMLSelectElement) {
    // Handle selection inputs
    if (name === 'collegeId' && value === 'others') {
      // When "Others" is selected, clear the college ID and focus on the custom input
      setFormData(prevData => ({
        ...prevData,
        collegeId: value,
        customCollegeName: '',
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  } else if (e.target instanceof HTMLTextAreaElement) {
    // Handle text area inputs
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  }
};
const handleCustomCollegeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevData => ({
      ...prevData,
      customCollegeName: e.target.value,
    }));
  };

const handleAddCollege = async () => {
    if (formData.customCollegeName) {
        // Logic to add the custom college to the colleges list or send it to the backend
        const newCollege = { name: formData.customCollegeName }; // Only send the name to the backend

        try {
            // Send the new college to the backend
            const response = await axios.post('/api/college/submit', newCollege); // Adjust the endpoint as necessary
            
            if (response.status === 201) {
                // On successful response, update local state with the new college
                const collegeWithId = { id: response.data.id, name: newCollege.name }; // Use the ID from the backend response
                setColleges(prevColleges => [...prevColleges, collegeWithId]);

                // Update formData to clear the custom college name and set collegeId
                setFormData(prevData => ({
                    ...prevData,
                    collegeId: collegeWithId.id,
                    customCollegeName: '',
                }));
            }
        } catch (error: unknown) {
            console.error('Error adding college:', error);
            // Check if error is an AxiosError and handle accordingly
            if (axios.isAxiosError(error) && error.response) {
                alert(error.response.data.error); // Display the error message from the backend
            } else {
                alert('An error occurred while adding the college.'); // General error message
            }
        }
    }
};

    const [fileNames, setFileNames] = useState({
    passportPhoto: '',
    aadharProof: '',
        });

 const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  if (!validity.usn || !validity.dob) {
    setErrorMessage('Please fill in all required fields and ensure they are valid.');
    return;
  }

  const formDataToSend = new FormData();

  for (const key in formData) {
    if (formData[key as keyof typeof formData] !== null) {
      formDataToSend.append(key, formData[key as keyof typeof formData] as string | Blob);
    }
  }

  try {
    const response = await completeRegistration(formDataToSend);

    console.log('Registration Response:', response);
    if (response && response.message) {
      localStorage.setItem('userEmail', formData.email as string);

      setMessage('Please verify your account by entering the OTP sent to your email ID.');
      navigate('/otp'); // Navigate to OTP page after successful registration
    } else {
      setErrorMessage('Registration failed: Please try again');
    }
  } catch (error) {
    console.error('Error during submission:', error);
    setErrorMessage('Registration failed');
  }
};

  const validateForm = () => {
    const newValidity = { ...validity }; // Create a copy to modify

    let isValid = true;

    // Check each required field

    if (!formData.name) {
      newValidity.name = false;
      isValid = false;
    } else {
      newValidity.name = true;
    }

    if (!formData.dob) {
      newValidity.dob = false;
      isValid = false;
    } else {
      newValidity.dob = true;
    }

    if (!formData.address) {
      newValidity.address = false;
      isValid = false;
    } else {
      newValidity.address = true;
    }

    if (!formData.collegeId) {
      newValidity.collegeId = false;
      isValid = false;
    } else {
      newValidity.collegeId = true;
    }

    if (!formData.degreeId) {
      newValidity.degreeId = false;
      isValid = false;
    } else {
      newValidity.degreeId = true;
    }

    if (!formData.branchId) {
      newValidity.branchId = false;
      isValid = false;
    } else {
      newValidity.branchId = true;
    }

    if (!formData.degreeStatusId) {
      newValidity.degreeStatusId = false;
      isValid = false;
    } else {
      newValidity.degreeStatusId = true;
    }

    if (!formData.university) {
      newValidity.university = false;
      isValid = false;
    } else {
      newValidity.university = true;
    }

    if (!formData.usn) {
      newValidity.usn = false;
      isValid = false;
    } else {
      newValidity.usn = true;
    }

    if (!formData.aadharNo) {
      newValidity.aadharNo = false;
      isValid = false;
    } else {
      newValidity.aadharNo = true;
    }

    if (!formData.gender) {
      newValidity.gender = false;
      isValid = false;
    } else {
      newValidity.gender = true;
    }

    if (!formData.semester) {
      newValidity.semester = false;
      isValid = false;
    } else {
      newValidity.semester = true;
    }

    if (!formData.phoneNumber) {
      newValidity.phoneNumber = false;
      isValid = false;
    } else {
      newValidity.phoneNumber = true;
    }

    // Special handling for file inputs
    if (!formData.passportPhoto) {
      newValidity.passportPhoto = false;
      isValid = false;
    } else {
      newValidity.passportPhoto = true;
    }

    if (!formData.aadharProof) {
      newValidity.aadharProof = false;
      isValid = false;
    } else {
      newValidity.aadharProof = true;
    }

    // Update the validity state
    setValidity(newValidity);

    return isValid;
  };

  return (
    <div className="form-background">
    <form onSubmit={handleSubmit} id="multiPartForm" className="reg-form-container">
    <h2 className="reg-form-heading">REGISTRATION</h2>
      {/* Personal Details Section */}
      <fieldset className="reg-form-section">
        <legend className="reg-form-section-title">Personal Details[as per AADHAAR]</legend>
        <div className="reg-form-group">
          <label htmlFor="name" className="reg-form-label">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            className="reg-form-input"
            value={formData.name}
            onChange={handleChange}
            style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        
        <div className="reg-form-group">
          <label htmlFor="gender" className="reg-form-label">Gender</label>
          <select
            id="gender"
            name="gender"
            className="reg-form-input"
            value={formData.gender}
            onChange={handleChange}
            style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="reg-form-group">
          <label htmlFor="phoneNumber" className="reg-form-label">Phone Number</label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            type="text" value={formData.phoneNumber}
      onChange={handleChange}
      placeholder="Phone Number"
      className="reg-form-input"
      style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
      required
    />
        </div>
        <div className="reg-form-group">
  <label htmlFor="email" className="reg-form-label">Email</label>
  <input
    id="email"
    name="email"
    type="email"
    className="reg-form-input"
    value={formData.email}
    onChange={handleChange}
    style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
  />
</div>
<div className="reg-form-group">
            <label htmlFor="dob" className="reg-form-label">Date of Birth</label>
            <input
              id="dob"
              name="dob"
              type="date"
              className="reg-form-input"
              value={formData.dob}
        onChange={handleChange}

       style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
        required
      />
      {!validity.dob && (
        <div style={{ color: 'red', marginTop: '8px' }}>{errorMessage}</div>
      )}

          </div>
        <div className="reg-form-group">
          <label htmlFor="address" className="reg-form-label">Address</label>
          <textarea
            id="address"
            name="address"
            placeholder="Address"
            className="reg-form-input"
            value={formData.address}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => handleChange(e)}

           style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
      </fieldset>

      {/* Educational Details Section */}
      <fieldset className="reg-form-section">
        <legend className="reg-form-section-title">Educational Details</legend>
       <div className="reg-form-group">
  <label htmlFor="collegeId" className="reg-form-label">College</label>
  <select
    id="collegeId"
    name="collegeId"
    className="reg-form-input"
    value={formData.collegeId}
    onChange={handleChange}
    required
    style={{ color: 'black' }} // Ensure this style is applied correctly
  >
    <option value="">Select College</option>
    <option value="others">Others</option>
    {colleges.map((college) => (
      <option key={college.id} value={college.id}>
        {college.name}
      </option>
    ))}
  </select>
</div>

{/* Conditional Input for Custom College Name */}
{formData.collegeId === 'others' && (
  <div className="reg-form-group">
    <label htmlFor="customCollegeName" className="reg-form-label">Enter College Name</label>
    <input
      id="customCollegeName"
      name="customCollegeName"
      type="text"
      className="reg-form-input"
      value={formData.customCollegeName}
      onChange={handleCustomCollegeChange}
      required
      style={{ color: 'black' }} // Ensure this style is applied correctly
    />
    <button
      type="button"
      id="go-back-btn"
      className="reg-button-go-back"
      onClick={handleAddCollege}
    >
      Add College
    </button>
  </div>
)}

        <div className="reg-form-group">
          <label htmlFor="degreeId" className="reg-form-label">Degree</label>
          <select
            id="degreeId"
            name="degreeId"
            className="reg-form-input"
            value={formData.degreeId}
            onChange={handleChange}
            style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Degree</option>
            {degrees.map((degree) => (
              <option key={degree.id} value={degree.id}>
                {degree.DegreeName}
              </option>
            ))}
          </select>
        </div>
        <div className="reg-form-group">
          <label htmlFor="branchId" className="reg-form-label">Branch</label>
          <select
            id="branchId"
            name="branchId"
            className="reg-form-input"
            value={formData.branchId}
            onChange={handleChange}
           style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Branch</option>
            {filteredBranches.map((branch) => (
              <option key={branch.BranchID} value={branch.BranchID}>
                {branch.BranchName}
              </option>
            ))}
          </select>
        </div>
        <div className="reg-form-group">
          <label htmlFor="degreeStatusId" className="reg-form-label">Degree Status</label>
          <select
            id="degreeStatusId"
            name="degreeStatusId"
            className="reg-form-input"
            value={formData.degreeStatusId}
            onChange={handleChange}
            style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Degree Status</option>
            {degreeStatuses.map((status) => (
              <option key={status.DegreeStatusID} value={status.DegreeStatusID}>
                {status.StatusName}
              </option>
            ))}
          </select>
        </div>
        <div className="reg-form-group">
          <label htmlFor="university" className="reg-form-label">University</label>
          <input
            id="university"
            name="university"
            type="text"
            placeholder="University"
            className="reg-form-input"
            value={formData.university}
            onChange={handleChange}
            style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        <div className="reg-form-group">
          <label htmlFor="usn" className="reg-form-label">USN</label>
          <input
            id="usn"
            name="usn"
            type="text"
            placeholder="USN"
            value={formData.usn}
            onChange={handleChange}
            className="reg-form-input"
             style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
      required
    />
        </div>
        <div className="reg-form-group">
          <label htmlFor="aadharNo" className="reg-form-label">Aadhar Number</label>
          <input
            id="aadharNo"
            name="aadharNo"
            type="text"
            placeholder="Aadhar Number"
            className="reg-form-input"
            value={formData.aadharNo}
            onChange={handleChange}
           style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        <div className="reg-form-group">
          <label htmlFor="semester" className="reg-form-label">Semester</label>
          <select
            id="semester"
            name="semester"
            className="reg-form-input"
            value={formData.semester}
            onChange={handleChange}
           style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Semester</option>
            {semesters.map((semester) => (
              <option key={semester.id} value={semester.id}>
                {semester.name}
              </option>
            ))}
          </select>
        </div>
        <div className="reg-form-group">
            <label htmlFor="textbook" className="reg-form-label">Text Book</label>
            <input id="textbook" name="textbook" type="text" placeholder="Text Book" className="reg-form-input"  value={formData.textbook}
            onChange={handleChange}
              style={{
                border: validity.semester ? '1px solid #ccc' : '2px solid red',
                padding: '8px',
                color: formData.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
           
            required
          />
          </div>
      </fieldset>

      {/* Documents Section */}
      <fieldset className="reg-form-section">
  <legend className="reg-form-section-title">Documents</legend>
  <div className="reg-form-group">
    <label htmlFor="passportPhoto" className="reg-form-label">Photo</label>
    <input
      id="passportPhoto"
      name="passportPhoto"
      type="file"
      className="reg-form-input"
      onChange={handleChange}
      style={{
        color: 'black'
      }}
    />
  </div>
  <div className="reg-form-group">
    <label htmlFor="aadharProof" className="reg-form-label">Aadhar Proof</label>
    <input
      id="aadharProof"
      name="aadharProof"
      type="file"
      className="reg-form-input"
      onChange={handleChange}
      style={{
        color: 'black'
      }}
    />
  </div>
</fieldset>

      {/* Submit Button */}
      <div className="reg-btn-container">
      <button type="submit" className="reg-form-submit">Submit</button>
     <button
      type="button"
      id="go-back-btn"
      className="reg-button-go-back"
      onClick={() => navigate('/')}
    >
      Go Back
    </button>
        </div>

        <div className="reg-additional-links">
         <p className="reg-login-link">
  Already have an account? <Link to="/log">Login here</Link>
</p>
          <img src="./image/log.webp" alt="Logo" className="reg-login-legend-logo" />
        </div>
    </form>
  </div>  
  );
};

export default Form;
