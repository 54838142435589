import React, { FC, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/logincss.css';

const Log: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleGoBack = (): void => {
    navigate(-1); // Navigate back to previous page
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const loginData = { IIMSTC_ID: email, password };
      const response = await axios.post('/api/users/login', loginData);
      const { token, user } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('IIMSTC_ID', user.IIMSTC_ID);
      localStorage.setItem('degreeStatusId', user.degreeStatusId);

      navigate('/user');
    } catch (error) {
      setError('Invalid email or password');
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className="login-container">
      <form id="loginForm" className="login-form" onSubmit={handleLogin}>
        <fieldset className="login-form-section">
          <legend className="login-form-legend">
            <span className="login-text"><strong>LOGIN</strong></span>
          </legend>

          <div className="login-form-group">
            <label htmlFor="IIMSTC_ID" className="login-form-label">Email</label>
            <input
              id="IIMSTC_ID"
              name="IIMSTC_ID"
              type="text"
              placeholder="IIMSTC ID"
              className="login-form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="login-form-group">
            <label htmlFor="password" className="login-form-label">Password</label>
            <div className="password-container">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                className="login-form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="show-password-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
        </fieldset>

        <div className="login-form-btn-container">
          <button type="submit" className="login-form-btn login-form-btn-submit">Login</button>
          <button type="button" className="login-form-btn login-form-btn-go-back" onClick={handleGoBack}>Go Back</button>
        </div>

        {error && <p className="login-error">{error}</p>}

        <div className="login-additional-links">
          <p>Don't have an account? <Link to="/register" className="login-link">Register Now</Link></p>
          <img src="./image/log.webp" alt="Logo" className="login-legend-logo" />
        </div>
      </form>
    </div>
  );
};

export default Log;
